import Modal from '../Modal';

import {Container, Details, Section, Text, VideoWrap } from './ui';

const VideoModal = ({ closeModal, modalRef, videoData, showModal }) => {

  const { description, director, id, title, type, year } = videoData;

  return (
    <Modal showModal={showModal} close={closeModal} modalRef={modalRef}>
      <Section />
      <Container>
        <VideoWrap>
          <iframe title={title} src={`https://www.youtube.com/embed/${id}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen" frameBorder="0"></iframe>
        </VideoWrap>
        <Details>
          {title && <Text><span>Title: </span>{title}</Text>}
          {description && <Text><span>Description: </span>{description}</Text>}
          {director && <Text><span>Director: </span>{director}</Text>}
          {year && <Text><span>Year: </span>{year}</Text>}
          {type && <Text><span>Type: </span>{type}</Text>}
        </Details>
      </Container>
    </Modal>
  );
};

export default VideoModal;