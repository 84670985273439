import { Container, Item, List, Text } from './ui';

const Details = ({ stats }) => {
  const {
    appearance,
    eyeColour,
    facialHair,
    hairColour,
    hairLength,
    height,
    location,
    nationality,
    playingAge,
    voiceCharacter,
    voiceQuality,
    weight
  } = stats || {};

  return (
    <Container>
      <List>
        {location && (
          <Item>
            <Text><span>Location: </span>{location}</Text>
          </Item>
        )}
        {height && (
          <Item>
            <Text><span>Height: </span>{height}</Text>
          </Item>
        )}
        {weight && (
          <Item>
            <Text><span>Weight: </span>{weight}</Text>
          </Item>
        )}
        {playingAge && (
          <Item>
            <Text><span>Playing Age: </span>{playingAge}</Text>
          </Item>
        )}
        {appearance && (
          <Item>
            <Text><span>Appearance: </span>{appearance}</Text>
          </Item>
        )}
        {nationality && (
          <Item>
            <Text><span>Nationality: </span>{nationality}</Text>
          </Item>
        )}
        {eyeColour && (
          <Item>
            <Text><span>Eye Colour: </span>{eyeColour}</Text>
          </Item>
        )}
        {hairColour && (
          <Item>
            <Text><span>Hair Colour: </span>{hairColour}</Text>
          </Item>
        )}
        {hairLength && (
          <Item>
            <Text><span>Hair Length: </span>{hairLength}</Text>
          </Item>
        )}
        {facialHair && (
          <Item>
            <Text><span>Facial Hair: </span>{facialHair}</Text>
          </Item>
        )}
        {voiceCharacter && (
          <Item>
            <Text><span>Voice Character: </span>{voiceCharacter}</Text>
          </Item>
        )}
        {voiceQuality && (
          <Item>
            <Text><span>Voice Quality: </span>{voiceQuality}</Text>
          </Item>
        )}
      </List>
    </Container>
  );
};

export default Details;