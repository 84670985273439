import DuoImage from '../../components/DuoImage';
import HeroText from '../../components/HeroText';
import Social from '../../components/Social';
import { HomeContainer } from '../Shared/ui';
import { breakpoints } from '../../ui';
import useWindowSize from '../../utils/useWindowSize';

const Home = ({ data }) => {
  const windowSize = useWindowSize();

  const { heroImages } = data || {};

  return (
    <HomeContainer>
      <DuoImage data={data} images={heroImages} />
      {windowSize.width < breakpoints.lg ? (
        <HeroText data={data} />
      ) : null}
      {windowSize.width < breakpoints.lg ? (
        <Social />
      ) : null}
    </HomeContainer>
  )
}; 

export default Home;