import styled from 'styled-components';
import { breakpoints, colors } from '../../ui';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  height: 100%;
  max-width: 100%;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.md}px) {
    grid-column-gap: 24px;
    grid-row-gap: 40px;
    margin-bottom: 32px;
  }


  @media (min-width: ${breakpoints.lg}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ImageWrap = styled.div`
  position: relative;
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  img {
    max-width: 100%;
  }
`;

const AwardBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 32px;
`;

const Details = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Text = styled.p`
  color: ${colors.white};
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.8;
  width: 100%;
  margin: 0;
  text-align: center;

  span {
    font-weight: 600;
    letter-spacing: 0.7px;
  }
`;

export { AwardBlock, Container, Details, ImageWrap, Text };