import { Container, Line } from './ui';

const Hamburger = ({ active, clicked }) => (
  <Container onClick={clicked} $active={active?.toString()}>
    <Line $active={active} className='top' />
    <Line $active={active} className='middle' />
    <Line $active={active} className='bottom' />
  </Container>
);

export default Hamburger;