import styled from 'styled-components';
import { breakpoints } from '../../ui';

const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  padding: 76px 0 0;

  @media (min-width: ${breakpoints.md}px) {
    padding: 92px 0 0;
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 120px 0 0;
  }
`;

const HomeContainer = styled(Container)`
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 76px;

  @media (min-width: ${breakpoints.md}px) {
    padding-top: 92px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding-top: 0;
    display: flex;
    align-items: center;
  }
`;

const NoPageContainer = styled(Container)`
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  @media (min-width: ${breakpoints.md}px) {
    padding: 0;
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 0;
  }
`;

export { Container, HomeContainer, NoPageContainer };