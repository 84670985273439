import { useRef } from 'react';
import { Container } from '../Shared/ui';
import { useModal } from '../../utils/useModal';
import Backdrop from '../../components/Backdrop';
import Title from '../../components/Title';
import VideoGrid from '../../components/VideoGrid';
import VideoModal from '../../components/VideoModal';
import Footer from '../../components/Footer';


const VideoGallery = ({ data }) => {
  const { videos } = data || {};
  const videoModal = useRef(null);

  const {
    closeModal, currentVideo, openVideoModal, showModal
  } = useModal(videoModal.current);

  return (
    <Container>
      <Title title="Videos" />
      <VideoGrid clicked={openVideoModal} videos={videos} />
      <Footer />
      <VideoModal showModal={showModal} closeModal={closeModal} videoData={currentVideo} modalRef={videoModal} />
      <Backdrop show={showModal} clicked={closeModal} />
    </Container>
  );
}; 

export default VideoGallery;