import { useRef } from 'react';
import { Container } from '../Shared/ui';
import { useModal } from '../../utils/useModal';
import Backdrop from '../../components/Backdrop';
import ImageGrid from '../../components/ImageGrid';
import ImageModal from '../../components/ImageModal';
import Title from '../../components/Title';
import Footer from '../../components/Footer';

const ImageGallery = ({ data }) => {
  const { headshots } = data || {};
  const imageModal = useRef(null);
  const {
    showModal, openImageModal, closeModal, currentImage
  } = useModal(imageModal.current);

  return (
    <Container>
      <Title title="Headshots" />
      <ImageGrid clicked={openImageModal} headshots={headshots} />
      <Footer />
      <ImageModal showModal={showModal} closeModal={closeModal} imageData={currentImage} modalRef={imageModal} />
      <Backdrop $show={showModal} clicked={closeModal} />
    </Container>
  );
}; 

export default ImageGallery;