import styled from 'styled-components';
import { breakpoints, colors } from '../../ui';

const StyledH1 = styled.h1`
  border-bottom: 1px solid ${colors.yellow};
  color: ${colors.yellow};
  font-family: 'Playfair Display', serif;
  font-size: 72px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 24px;
  position: relative;
  margin: 0;
  margin-bottom: 32px;
  text-align: center;
  width: min-content;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 96px;
    margin-bottom: 36px;
  }
`;

const Description = styled.p`
  color: ${colors.white};
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 1.8;
  width: 100%;
  margin: 0;
  text-align: center;
`;

export { Description, StyledH1 };