import { AwardBlock, Container, Details, ImageWrap, Text } from './ui';

const AwardGrid = ({ awards }) => {
  return (
    <Container>
      {(awards ?? []).map((award, index) => {
        return (
          <AwardBlock>
            <ImageWrap key={index} >
              <img src={award.src} alt={award.title} />
            </ImageWrap>
            <Details>
              <Text><span>Title:</span> {award.title}</Text>
              <Text><span>Project:</span> {award.project}</Text>
              <Text><span>Presenter:</span> {award.presenter}</Text>
            </Details>
          </AwardBlock>
        )
      })}
    </Container>
  );
};

export default AwardGrid;