import { Outlet } from 'react-router-dom';
import { DesktopNav, MobileNav } from '../../components/Navigation';
import useWindowSize from '../../utils/useWindowSize';
import { breakpoints } from '../../ui';
import { Container, Inner, Page } from './ui';

const Layout = ({ data }) => {
  const windowSize = useWindowSize();
  const { cv, links } = data || {};

  return (
    <Page>
      <Container>
        <Inner>
          {windowSize.width < breakpoints.md ?
            <MobileNav cv={cv} links={links} /> :
            <DesktopNav cv={cv} links={links} />
          }
        </Inner>
      </Container>
      <Outlet />
    </Page>
  )
};

export default Layout;