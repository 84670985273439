import { Close } from '../SVGs';
import { Container, CloseButton } from './ui';

const Modal = ({ children, modalRef, showModal, close }) => {
  return (
    <Container className="modal" $show={showModal} ref={modalRef}>
      {children}
      <CloseButton $show={showModal} onClick={close}>
        <Close />
      </CloseButton>
    </Container>
  );
};

export default Modal;