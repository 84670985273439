import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import {
  Audio,
  Awards,
  Home,
  ImageGallery,
  Layout,
  NoPage,
  PersonalStats,
  Showreel,
  VideoGallery
} from './pages';

export default function App() {
  const [siteData, setSiteData] = useState({});

  useEffect(() => {
    axios.get('data/data.json')
      .then((res)=>{
        setSiteData(res.data);
      }).catch((err)=>{
        console.log(err);
      });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout data={siteData} />}>
          <Route index element={<Home data={siteData} />} />
          <Route path="awards" element={<Awards data={siteData} />} />
          <Route path="image-gallery" element={<ImageGallery data={siteData} />} />
          <Route path="showreel" element={<Showreel data={siteData} />} />
          <Route path="stats" element={<PersonalStats data={siteData} />} />
          <Route path="video-gallery" element={<VideoGallery data={siteData} />} />
          <Route path="audio" element={<Audio data={siteData} />} />
          <Route path="*" element={<NoPage data={siteData} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};