import { NoPageContainer } from '../Shared/ui';
import { Description, StyledH1 } from './ui';

const NoPage = ({ data }) => {
  const { noPageTitle, noPageSubtitle } = data || {};
  return (
    <NoPageContainer>
      <StyledH1>{noPageTitle}</StyledH1>
      <Description>{noPageSubtitle}</Description>
    </NoPageContainer>
  );
};

export default NoPage;