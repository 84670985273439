import { Container } from '../Shared/ui';
import AwardGrid from '../../components/AwardGrid';
import Title from '../../components/Title';
import Footer from '../../components/Footer';

const Awards = ({ data }) => {
  const { awards } = data || {};

  return (
    <Container>
      <Title title="Awards" />
      <AwardGrid awards={awards} />
      <Footer />
    </Container>
  );
}; 

export default Awards;