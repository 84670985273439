import { Image, ImgContain, Inner } from './ui';
import { Container } from '../Shared/ui';
import Details from '../../components/Details';
import Title from '../../components/Title';
import Footer from '../../components/Footer';

const PersonalStats = ({ data }) => {
  const { heroImages = [], stats } = data || {};
  const statsImage = heroImages[0];
  const { alt, src } = statsImage || {};

  return (
    <Container>
      <Title title="Stats" />
      <Inner>
        <Details stats={stats} />
        <Image>
          <ImgContain>
            <img src={src} alt={alt} />
          </ImgContain>
        </Image>
      </Inner>
      <Footer />
    </Container>
  );
}; 

export default PersonalStats;