import { VideoWrap } from './ui';
import { Container } from '../Shared/ui';
import Title from '../../components/Title';
import Footer from '../../components/Footer';


const Showreel = ({ data }) => {
  const { videos } = data || {};
  const [showreel] = videos || [];
  const { id } = showreel || {};

  return (
    <Container>
      <Title title="Showreel" />
      <VideoWrap>
        <iframe title="showreel" src={`https://www.youtube.com/embed/${id}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen" frameBorder="0"></iframe>
      </VideoWrap>
      <Footer />
    </Container>
  );
}; 

export default Showreel;