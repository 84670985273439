const Name = () => (
  <svg width="468" height="156" viewBox="0 0 468 156" xmlns="http://www.w3.org/2000/svg">
    <title>Tristan Pretty</title>
    <path id="letter-t-1" fill="#ffd018" d="M52.47,1.25Q52.16,6,52,10.38t-.12,6.72c0,1.12,0,2.18.08,3.2s.11,1.86.16,2.56H50.31A43.42,43.42,0,0,0,45.87,11,17.64,17.64,0,0,0,40.35,4.9a11,11,0,0,0-5.8-1.8h-.08V48.86a13.29,13.29,0,0,0,.68,4.88,3.59,3.59,0,0,0,2.6,2.08,24.65,24.65,0,0,0,5.6.48v1.6q-2.81-.09-7.56-.16t-10-.09q-5,0-9.36.09c-2.93,0-5.31.1-7.12.16V56.3a24.65,24.65,0,0,0,5.6-.48,3.59,3.59,0,0,0,2.6-2.08,13.29,13.29,0,0,0,.68-4.88V3.1h-.08a11,11,0,0,0-5.8,1.8A17.4,17.4,0,0,0,6.87,11,45,45,0,0,0,2.39,22.86H.55a21,21,0,0,0,.2-2.56c0-1,0-2.08,0-3.2q0-2.33-.12-6.72T.23,1.25q3.84.09,8.48.16c3.09.06,6.19.08,9.28.08h16.8c3.09,0,6.19,0,9.28-.08S50,1.31,52.47,1.25Z"/>
    <path id="letter-r" fill="#ffd018" d="M54.87,1.25q2.33.09,6.12.16c2.53.06,5.08.08,7.64.08s5.23,0,7.68-.08,4.19-.08,5.2-.08q12.17,0,18.12,3.41a11.28,11.28,0,0,1,6,10.51,14.52,14.52,0,0,1-1.2,5.77,13.27,13.27,0,0,1-4.08,5.08,21.48,21.48,0,0,1-7.88,3.6A51.25,51.25,0,0,1,79.75,31H72.07V29.41H79c2.72,0,4.79-.59,6.2-1.79A9.08,9.08,0,0,0,88,22.82a27.07,27.07,0,0,0,.76-6.68q0-6.33-1.72-9.81a6.11,6.11,0,0,0-6-3.47c-1.81,0-3,.45-3.52,1.36s-.8,2.74-.8,5.52V49.41a16,16,0,0,0,.44,4.33,2.75,2.75,0,0,0,1.64,1.91,12.22,12.22,0,0,0,3.6.65v1.6c-1.55-.11-3.53-.18-6-.2s-4.89-.05-7.4-.05q-4.32,0-8.12.05t-6.12.2V56.3a12.51,12.51,0,0,0,3.68-.65,2.66,2.66,0,0,0,1.6-1.91,17.21,17.21,0,0,0,.4-4.33V9.74a17.05,17.05,0,0,0-.4-4.36,2.76,2.76,0,0,0-1.64-1.92,10.63,10.63,0,0,0-3.64-.6Zm16.72,28.4c1.92.06,3.73.17,5.44.33l4.72.43c1.44.14,2.67.23,3.68.29q8.56.39,12.84,2.95a10.79,10.79,0,0,1,5.24,7.53l1.68,8.31a11.93,11.93,0,0,0,1.44,4.08,2.51,2.51,0,0,0,2.16,1.13,3.44,3.44,0,0,0,1.36-.25,3.39,3.39,0,0,0,1.12-.8l1,1.05a13.34,13.34,0,0,1-5.4,3.4,22.07,22.07,0,0,1-6.6.92,16.48,16.48,0,0,1-8.4-1.88Q88.72,55.25,88,50.38l-1.2-8a46.86,46.86,0,0,0-1.2-5.85,11.26,11.26,0,0,0-1.92-4A4,4,0,0,0,80.39,31H71.75Z"/>
    <path id="letter-i" fill="#ffd018" d="M142.31,1.25V2.86a10.63,10.63,0,0,0-3.64.6A2.76,2.76,0,0,0,137,5.38a17.05,17.05,0,0,0-.4,4.36V49.41a16,16,0,0,0,.44,4.33,2.75,2.75,0,0,0,1.64,1.91,12.22,12.22,0,0,0,3.6.65v1.6c-1.55-.11-3.53-.18-6-.2s-4.89-.05-7.4-.05q-4.32,0-8.12.05t-6.12.2V56.3a12.51,12.51,0,0,0,3.68-.65,2.66,2.66,0,0,0,1.6-1.91,17.21,17.21,0,0,0,.4-4.33V9.74a17.05,17.05,0,0,0-.4-4.36,2.76,2.76,0,0,0-1.64-1.92,10.63,10.63,0,0,0-3.64-.6V1.25q2.33.09,6.12.16c2.53.06,5.24.08,8.12.08,2.51,0,5,0,7.4-.08S140.76,1.31,142.31,1.25Z"/>
    <path id="letter-s" fill="#ffd018" d="M165,.14a24.84,24.84,0,0,1,8.08,1,34.86,34.86,0,0,1,4.88,2.16,16.52,16.52,0,0,0,1.56.84,2.72,2.72,0,0,0,1.16.29c.85,0,1.44-1.18,1.76-3.52h1.84c0,1.12-.12,2.42-.2,3.92s-.14,3.46-.16,5.92,0,5.68,0,9.68H182a27.8,27.8,0,0,0-2-8.56A18.79,18.79,0,0,0,175.23,5a10.27,10.27,0,0,0-7.16-2.72,8.83,8.83,0,0,0-5.84,1.92,6.72,6.72,0,0,0-2.32,5.52,8.85,8.85,0,0,0,1.44,5.08,19.64,19.64,0,0,0,4.52,4.43q3.07,2.33,8.12,5.85a79.75,79.75,0,0,1,6.52,5,20.79,20.79,0,0,1,4.68,5.59A15.16,15.16,0,0,1,187,43.18a13.22,13.22,0,0,1-3,8.88,18,18,0,0,1-8,5.24A33,33,0,0,1,165.19,59a31.57,31.57,0,0,1-8.68-1,42.23,42.23,0,0,1-5.48-2,5.77,5.77,0,0,0-2.72-1.12c-.86,0-1.44,1.17-1.76,3.51h-1.84c.1-1.38.17-3,.2-4.84s.05-4.22.08-7.16,0-6.66,0-11.19h1.84a44.56,44.56,0,0,0,1.8,10.48,18,18,0,0,0,4.56,7.87,11.23,11.23,0,0,0,8.2,3,10.89,10.89,0,0,0,7-2.08,7,7,0,0,0,2.6-5.75,11.36,11.36,0,0,0-3.28-8.29,55,55,0,0,0-8.4-6.75q-3.76-2.57-7-5.12a21.64,21.64,0,0,1-5.24-5.76,14.27,14.27,0,0,1-2-7.61,12.9,12.9,0,0,1,2.8-8.6,16.48,16.48,0,0,1,7.36-4.91A28.91,28.91,0,0,1,165,.14Z"/>
    <path id="letter-t-2" fill="#ffd018" d="M241.59,1.25c-.22,3.15-.36,6.19-.44,9.13S241,15.55,241,17.1c0,1.12,0,2.18.08,3.2s.1,1.86.16,2.56h-1.84A43.42,43.42,0,0,0,235,11a17.84,17.84,0,0,0-5.52-6.12,11,11,0,0,0-5.8-1.8h-.08V48.86a13.08,13.08,0,0,0,.68,4.88,3.59,3.59,0,0,0,2.6,2.08,24.59,24.59,0,0,0,5.6.48v1.6q-2.81-.09-7.56-.16t-10-.09q-5,0-9.36.09t-7.12.16V56.3a24.65,24.65,0,0,0,5.6-.48,3.61,3.61,0,0,0,2.6-2.08,13.29,13.29,0,0,0,.68-4.88V3.1h-.08a11,11,0,0,0-5.8,1.8A17.3,17.3,0,0,0,196,11a45,45,0,0,0-4.48,11.88h-1.84a23.23,23.23,0,0,0,.2-2.56c0-1,0-2.08,0-3.2q0-2.33-.12-6.72t-.44-9.13c2.56.06,5.38.11,8.48.16s6.18.08,9.28.08h16.8c3.09,0,6.18,0,9.28-.08S239.08,1.31,241.59,1.25Z"/>
    <path id="letter-a" fill="#ffd018" d="M277.51,1.25l19.36,49.93a10.71,10.71,0,0,0,2.4,3.88,4.19,4.19,0,0,0,2.32,1.24v1.6q-2.88-.16-6.72-.2c-2.56,0-5.18-.05-7.84-.05s-5.28,0-7.68.05-4.32.09-5.76.2V56.3q4.08-.16,5.16-1.4c.72-.83.54-2.6-.52-5.33l-12.8-35.43,1.6-2.81L256,40.06q-2.64,6.87-2.6,10.32t2,4.6a11.51,11.51,0,0,0,5.2,1.32v1.6c-2-.11-3.75-.18-5.32-.2s-3.3-.05-5.16-.05c-1,0-2.1,0-3.24.05a24.94,24.94,0,0,0-2.92.2V56.3a6.57,6.57,0,0,0,3.64-2.36,24.57,24.57,0,0,0,3.72-7L269.11,1.25c1.28.11,2.68.16,4.2.16S276.23,1.36,277.51,1.25Zm2.8,33.61v1.59H256.47l.8-1.59Z"/>
    <path id="letter-n" fill="#ffd018" d="M358.23,1.25V2.86a7,7,0,0,0-3.64,1.68A6.32,6.32,0,0,0,353,8.25a47.68,47.68,0,0,0-.4,7.08V57.9a19.2,19.2,0,0,0-2.6-.16,29.07,29.07,0,0,0-3,.16L311.51,19.17v23a56.29,56.29,0,0,0,.44,8.19,5.81,5.81,0,0,0,2.16,4.08,12.83,12.83,0,0,0,5.48,1.81v1.6c-1.07-.11-2.46-.18-4.16-.2s-3.31-.05-4.8-.05c-1.18,0-2.38,0-3.6.05a26,26,0,0,0-3,.2V56.3a7.66,7.66,0,0,0,3.68-1.73,6.44,6.44,0,0,0,1.6-3.71,49.15,49.15,0,0,0,.4-7V9.74a17.53,17.53,0,0,0-.4-4.36,2.78,2.78,0,0,0-1.64-1.92,10.63,10.63,0,0,0-3.64-.6V1.25c.8.06,1.81.11,3,.16s2.42.08,3.6.08,2.64,0,3.92-.08,2.42-.1,3.44-.16l32.72,34.57V16.94a57.83,57.83,0,0,0-.44-8.2,6.1,6.1,0,0,0-2.12-4.12,11.54,11.54,0,0,0-5.52-1.76V1.25c1.06.06,2.45.11,4.16.16s3.3.08,4.8.08c1.22,0,2.44,0,3.64-.08S357.43,1.31,358.23,1.25Z"/>
    <path id="letter-p-hollow" fill="none" stroke="#ffd018" strokeMiterlimit="10" d="M178.11,101.74q-6-4.39-18.12-4.4c-1,0-2.75,0-5.2.08s-5,.08-7.68.08-5.11,0-7.64-.08-4.57-.11-6.12-.16v1.6a10.63,10.63,0,0,1,3.64.6,2.8,2.8,0,0,1,1.64,1.92,17.11,17.11,0,0,1,.4,4.36v39.68a17.17,17.17,0,0,1-.4,4.32,2.7,2.7,0,0,1-1.6,1.92,12.92,12.92,0,0,1-3.68.64v1.6l7-.16c2.45-.05,4.91-.08,7.36-.08q5,0,9.36.08c2.93,0,5.31.11,7.12.16v-1.84a24.65,24.65,0,0,1-5.6-.48,3.55,3.55,0,0,1-2.6-2.08,13.26,13.26,0,0,1-.68-4.88V131h3a40.42,40.42,0,0,0,12.68-1.68,21.42,21.42,0,0,0,7.88-4.36,15.54,15.54,0,0,0,4.08-5.84,17,17,0,0,0,1.2-6A13.53,13.53,0,0,0,178.11,101.74Zm-11.6,19.84a11.34,11.34,0,0,1-2.88,5.68q-2.12,2.16-6.2,2.16h-2.16V105.74q0-4.15.8-5.52c.53-.91,1.71-1.36,3.52-1.36a6,6,0,0,1,6,3.76q1.72,3.76,1.72,11.36A35.79,35.79,0,0,1,166.51,121.58Z"/>
    <path id="letter-r-hollow" fill="none" stroke="#ffd018" strokeMiterlimit="10" d="M245.11,149.66a3.84,3.84,0,0,1-1.12.8,3.45,3.45,0,0,1-1.36.24,2.5,2.5,0,0,1-2.16-1.12A12.1,12.1,0,0,1,239,145.5l-1.68-8.32a10.78,10.78,0,0,0-5.24-7.52q-4.1-2.44-12.06-2.92a37.42,37.42,0,0,0,6.22-1,21.48,21.48,0,0,0,7.88-3.6,13.13,13.13,0,0,0,4.08-5.08,14.51,14.51,0,0,0,1.2-5.76,11.3,11.3,0,0,0-6-10.52q-6-3.4-18.12-3.4c-1,0-2.75,0-5.2.08s-5,.08-7.68.08-5.11,0-7.64-.08-4.58-.11-6.12-.16v1.6a10.63,10.63,0,0,1,3.64.6,2.82,2.82,0,0,1,1.64,1.92,17.58,17.58,0,0,1,.4,4.36v39.68a17.66,17.66,0,0,1-.4,4.32,2.7,2.7,0,0,1-1.6,1.92,12.92,12.92,0,0,1-3.68.64v1.6c1.54-.11,3.59-.17,6.12-.2s5.24,0,8.12,0c2.51,0,5,0,7.4,0s4.41.09,6,.2v-1.6a12.61,12.61,0,0,1-3.6-.64,2.79,2.79,0,0,1-1.64-1.92,16,16,0,0,1-.44-4.32V127h3.6a4,4,0,0,1,3.28,1.48,11.18,11.18,0,0,1,1.92,4,46.68,46.68,0,0,1,1.2,5.84l1.2,8c.48,3.25,1.79,5.51,3.92,6.76a16.48,16.48,0,0,0,8.4,1.88,22.07,22.07,0,0,0,6.6-.92,13.23,13.23,0,0,0,5.4-3.4Zm-26.12-26q-2.12,1.8-6.2,1.8h-2.16V105.74c0-2.77.26-4.61.8-5.52s1.7-1.36,3.52-1.36a6.1,6.1,0,0,1,6,3.48c1.14,2.32,1.72,5.59,1.72,9.8a27.07,27.07,0,0,1-.76,6.68A9,9,0,0,1,219,123.62Z"/>
    <path id="letter-e-hollow" fill="none" stroke="#ffd018" strokeMiterlimit="10" d="M297.11,153.9c-2.72-.11-5.79-.17-9.2-.2s-6.46,0-9.12,0h-7.72q-4.85,0-10.24,0c-3.6,0-6.95.09-10,.2v-1.6a12.92,12.92,0,0,0,3.68-.64,2.7,2.7,0,0,0,1.6-1.92,17.66,17.66,0,0,0,.4-4.32V105.74a17.58,17.58,0,0,0-.4-4.36,2.82,2.82,0,0,0-1.64-1.92,10.63,10.63,0,0,0-3.64-.6v-1.6l10,.16q5.4.08,10.24.08h7.72q3.68,0,8.36,0c3.12,0,5.9-.09,8.36-.2-.22,2.67-.36,5.24-.44,7.72s-.12,4.39-.12,5.72c0,1.12,0,2.19.08,3.2s.1,1.87.16,2.56h-1.84a29,29,0,0,0-3.2-10.44,12.79,12.79,0,0,0-11.52-6.92h-1a10.12,10.12,0,0,0-3.12.36,2.17,2.17,0,0,0-1.4,1.4,10.86,10.86,0,0,0-.36,3.28v20.48h.8a8.58,8.58,0,0,0,5.28-1.68,14,14,0,0,0,3.68-4.12,15.65,15.65,0,0,0,1.92-4.76h1.84c-.21,3-.31,5.36-.28,7s0,3.07,0,4.4,0,2.8.08,4.4.18,3.92.4,7h-1.84a14.67,14.67,0,0,0-2-5.36,11.32,11.32,0,0,0-3.8-3.8,10.23,10.23,0,0,0-5.36-1.4h-.8V147a10.92,10.92,0,0,0,.36,3.24,2.17,2.17,0,0,0,1.4,1.44,10.12,10.12,0,0,0,3.12.36h2.64a11,11,0,0,0,5.56-1.72,16.42,16.42,0,0,0,5.4-5.88A34,34,0,0,0,295,133.1h1.84q-.24,2.88-.24,7.36c0,1.33,0,3.24.08,5.72S296.84,151.23,297.11,153.9Z"/>
    <path id="letter-t-hollow-1" fill="none" stroke="#ffd018" strokeMiterlimit="10" d="M354,97.26c-.22,3.14-.36,6.18-.44,9.12s-.12,5.17-.12,6.72c0,1.12,0,2.18.08,3.2s.1,1.86.16,2.56h-1.84A43.42,43.42,0,0,0,347.39,107a17.84,17.84,0,0,0-5.52-6.12,11,11,0,0,0-5.8-1.8H336v45.76a13.08,13.08,0,0,0,.68,4.88,3.59,3.59,0,0,0,2.6,2.08,24.59,24.59,0,0,0,5.6.48v1.6q-2.81-.09-7.56-.16t-10-.09q-5,0-9.36.09t-7.12.16v-1.6a24.65,24.65,0,0,0,5.6-.48,3.61,3.61,0,0,0,2.6-2.08,13.29,13.29,0,0,0,.68-4.88V99.1h-.08a11,11,0,0,0-5.8,1.8,17.3,17.3,0,0,0-5.48,6.08,45,45,0,0,0-4.48,11.88h-1.84a23.23,23.23,0,0,0,.2-2.56c0-1,0-2.08,0-3.2q0-2.32-.12-6.72t-.44-9.12l8.48.15c3.09.06,6.18.08,9.28.08h16.8c3.09,0,6.18,0,9.28-.08Z"/>
    <path id="letter-t-hollow-2" fill="none" stroke="#ffd018" strokeMiterlimit="10" d="M410.87,97.26c-.22,3.14-.36,6.18-.44,9.12s-.12,5.17-.12,6.72c0,1.12,0,2.18.08,3.2s.1,1.86.16,2.56h-1.84A43.42,43.42,0,0,0,404.27,107a17.84,17.84,0,0,0-5.52-6.12,11,11,0,0,0-5.8-1.8h-.08v45.76a13.08,13.08,0,0,0,.68,4.88,3.59,3.59,0,0,0,2.6,2.08,24.59,24.59,0,0,0,5.6.48v1.6q-2.81-.09-7.56-.16t-10-.09q-5,0-9.36.09t-7.12.16v-1.6a24.65,24.65,0,0,0,5.6-.48,3.61,3.61,0,0,0,2.6-2.08,13.29,13.29,0,0,0,.68-4.88V99.1h-.08a11,11,0,0,0-5.8,1.8,17.3,17.3,0,0,0-5.48,6.08,45,45,0,0,0-4.48,11.88H359a23.23,23.23,0,0,0,.2-2.56c0-1,0-2.08,0-3.2q0-2.32-.12-6.72t-.44-9.12l8.48.15c3.09.06,6.18.08,9.28.08h16.8c3.09,0,6.18,0,9.28-.08Z"/>
    <path id="letter-y-hollow" fill="none" stroke="#ffd018" strokeMiterlimit="10" d="M467.11,97.18v1.6a12.59,12.59,0,0,0-3.92,3.32,38.44,38.44,0,0,0-4.56,7.32l-7.44,14.76v21.24a15.56,15.56,0,0,0,.44,4.32,2.79,2.79,0,0,0,1.64,1.92,12.61,12.61,0,0,0,3.6.64v1.6c-1.55-.11-3.6-.17-6.16-.2s-5.1,0-7.6,0q-4.08,0-7.88,0c-2.54,0-4.52.09-6,.2v-1.6a12.92,12.92,0,0,0,3.68-.64,2.7,2.7,0,0,0,1.6-1.92,17.66,17.66,0,0,0,.4-4.32V130.73l-14.8-27.39a11.49,11.49,0,0,0-2.56-3.52,3.59,3.59,0,0,0-2.08-1V97.18q2.48.24,5.16.36t5.88.12c2.72,0,5.66,0,8.84-.16s6.09-.21,8.76-.32v1.68c-1.34,0-2.56,0-3.68.16a4.48,4.48,0,0,0-2.4.76c-.48.4-.48,1.11,0,2.12l11.43,21.95,3.61-7.23q3.36-6.65,3.72-10.48t-1.4-5.56a7.05,7.05,0,0,0-5-1.8v-1.6l5.4.16c1.57,0,3.26.08,5.08.08,1.06,0,2.16,0,3.28-.08S466.31,97.23,467.11,97.18Z"/>
  </svg>
);

export default Name;