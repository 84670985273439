import styled from 'styled-components';
import { colors } from '../../ui';

const Line = styled.span`
  background-color: ${({ $active }) => $active ? `${colors.darkGrey}` :  `${colors.yellow}`};
  height: 1px;
  width: 20px;
  position: relative;
  transform-origin: 50% 50%;
  transition: color 0.5s;

  &.bottom {
    transform: ${({ $active }) => $active ? `translateY(-7px) rotate(-45deg)` : `rotate(0deg) translateY(0px)`};
	  transition: 0.5s;
  }

  &.top {
    transform: ${({ $active }) => $active ? `translateY(7px) rotate(45deg)` : `rotate(0deg) translateY(0px)`};
	  transition: 0.5s;
  }

  &.middle {
    opacity: 1;
    opacity: ${({ $active }) => $active ? `0` : `1`};
    transition: 0.6s;
  }
`;

const Container = styled.div`
  cursor: pointer;
  width: 20px;
  height: 14px;
  overflow: hidden;
  position: relative;
  z-index: 3;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export { Container, Line };