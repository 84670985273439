import { Audio, AudioBlock, Container, Details, Text } from './ui';


const AudioGrid = ({ audioBites }) => {
  return (
    <Container>
      {(audioBites ?? []).map((bite, index) => {
        return (
          <div key={index}>
            {bite.src &&
              <AudioBlock key={index}>
                <Audio controls>
                  <source src={bite.src} type={`audio/${bite.type}`} />
                  Your browser does not support the audio element.
                </Audio>
                <Details>
                  {bite.title && <Text>{bite.title}</Text>}
                  {bite.description && <Text>{bite.description}</Text>}
                </Details>
              </AudioBlock>
            }
          </div>
        )
      })}
    </Container>
  );
};

export default AudioGrid;