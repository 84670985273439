import { Container } from '../Shared/ui';
import Title from '../../components/Title';
import Footer from '../../components/Footer';
import AudioGrid from '../../components/AudioGrid';
import { Text } from '../../components/Details/ui';
const Audio = ({ data }) => {
  const { audio } = data || {};

  return (
    <Container>
      <Title title="Audio" />
        {audio?.length > 0 ?
          <AudioGrid audioBites={audio} /> :
          <Text>Audio samples coming soon</Text>
        }
      <Footer />
    </Container>
  );
};

export default Audio;