import Modal from '../Modal';

import {Container, Details, ImageWrap, Section, Text } from './ui';
import { breakpoints } from '../../ui';
import useWindowSize from '../../utils/useWindowSize';

const ImageModal = ({ closeModal, imageData, modalRef, showModal }) => {
  const windowSize = useWindowSize();
  const { photographer, photographerSite, src, thumbSrc, title, year } = imageData;

  return (
    <Modal showModal={showModal} close={closeModal} modalRef={modalRef}>
      <Section />
      <Container>
        {windowSize.width > breakpoints.sm ?
          <ImageWrap>
            <img src={src} alt={title} />
          </ImageWrap> :
          <ImageWrap>
            <img src={thumbSrc} alt={title} />
          </ImageWrap>
        }
        <Details>
          {title && <Text><span>Title: </span>{title}</Text>}
          {photographer && <Text><span>Photographer: </span>{photographer}</Text>}
          {photographerSite && <Text><span>Website: </span>{photographerSite}</Text>}
          {year && <Text><span>Year: </span>{year}</Text>}
        </Details>
      </Container>
    </Modal>
  );
};

export default ImageModal;